import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import styles from './team_section.module.css'
import Quadriptych from '../quadriptych/quadriptych'

import img1 from './staff-in-guadalajara-office.jpg'
import img2 from './staff-in-melbourne-office.jpg'
import img3 from './four-people-at-envato-worldwide-author-conference-2019.jpg'
import img4 from './two-people-posing.jpg'

const TeamSection = () => (
  <div className={styles.root}>
    <div className={styles.container}>
      <div className={styles.quadriptych}>
        <Quadriptych
          img1={<img loading='lazy' src={img1} alt='Staff in Guadalajara office' />}
          img2={<img loading='lazy' src={img2} alt='Staff in Melbourne office' />}
          img3={<img loading='lazy' src={img3} alt='Four people at Envato Worldwide Author Conference 2019' />}
          img4={<img loading='lazy' src={img4} alt='Two people posing' />}
        />
      </div>

      <div className={styles.body}>
        <h2>
          With offices in Melbourne and Guadalajara, our Envato team passionately supports millions of digital creatives
          around the world.
        </h2>
        <p>
          We’re a team that knows creativity doesn’t just happen; it takes passion and a lot of hard work. That’s why
          we’re committed to making it easier for people to get their creative projects done, driven by a positive
          purpose and a shared set of values that puts our community at the heart of everything we do.
        </p>
        <AnchorWithArrow className={styles.link} href='https://www.envato.com/about/'>
          Read more about us
        </AnchorWithArrow>
      </div>
    </div>
  </div>
)

export default TeamSection
