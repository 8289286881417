import React from 'react'
import PropTypes from 'prop-types'
import styles from './quadriptych.module.css'

const Quadriptych = ({ img1, img2, img3, img4 }) => (
  <div className={styles.root}>
    <div className={styles.container}>
      {img1}
      {img2}
      {img3}
      {img4}
    </div>
  </div>
)

Quadriptych.propTypes = {
  img1: PropTypes.node.isRequired,
  img2: PropTypes.node.isRequired,
  img3: PropTypes.node.isRequired,
  img4: PropTypes.node.isRequired
}

export default Quadriptych
